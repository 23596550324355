import React, { useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import InputContainer from '../../components/form-control/InputContainer';
import Loading from '../../components/loading/Loading';
import useError from '../../hooks/useError';
import { useLoginMutation } from '../../store/auth/auth.api';
import { useSelector } from 'react-redux';
import { selectIsUserLogged } from '../../store/auth/auth.slice';

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLogged = useSelector(selectIsUserLogged);

  const [login, { isLoading, isError, error }] = useLoginMutation();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const buttonDisabled = !username || !password;

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!buttonDisabled) {
      login({ emailOrUsername: username, password })
        .unwrap()
        .then(() => {
          // Redirect to the previous page
          const from = (location.state as any)?.from || '/creator';
          navigate(from);
        })
        .catch((err) => console.error(err));
    }
  };

  const errorMessage = useError({ isError, error });

  if (isLoading) return <Loading />;
  return (
    <>
      <Navbar />
      <Container className='py-5 page-container'>
        <Row>
          <Col sm={12} md={{ span: 6, offset: 3 }}>
            <h1 className='text-center'>Welcome to SmartQuiz</h1>
            <p className='text-center'>SmartQuiz is a platform to create a set of
              <strong>high-quality multiple-choice questions</strong> from YouTube videos.
            </p>
            {/* Spacer */}
            <div className='mb-4'></div>
            {isLogged ? (
              <div className='text-center'>
                <Link to='creator'>
                  <Button variant='primary' size='lg'>
                    Go to dashboard
                  </Button>
                </Link>
              </div>
            ) : (
              <Form onSubmit={onSubmit}>
                <h2>Login</h2>
                <p>Please, insert the provided username and password to login.</p>
                {/* Username */}
                <InputContainer formGroupClassName='mb-4' label='Username' id='username'>
                  <Form.Control id='username' autoComplete='username' type='text' placeholder='Enter username' value={username} onChange={(e) => setUsername(e.target.value)} />
                </InputContainer>
                {/* Password */}
                <InputContainer formGroupClassName='mb-4' label='Password' id='password'>
                  <Form.Control id='password' autoComplete='current-password' type='password' placeholder='Enter password' value={password} onChange={(e) => setPassword(e.target.value)} />
                </InputContainer>
                {/* Error message */}
                {isError && <Alert variant='danger'>{errorMessage}</Alert>}
                {/* Submit */}
                <div className='text-center'>
                  <Button variant='primary' type='submit' disabled={buttonDisabled}>
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
